import React, { useState, useEffect } from 'react';
import './Comprar.css';
import { FaFilePdf, FaInfoCircle } from 'react-icons/fa';
import PreviewModal from '../Modal/PreviewModal';
import caratula_ingles from '../pdf/indice_ingles.pdf'; 
import caratula_español from '../pdf/indice_español.pdf';
import inglesImg from '../img/ingles.png';
import españolImg from '../img/español.png';

function Comprar({ language }) {
    const [isPreviewOpen, setIsPreviewOpen] = useState(false);
    const [currentPdf, setCurrentPdf] = useState('');

    useEffect(() => {
        window.scrollTo(0, 0); 
    }, []);

    const openPreview = (pdfSrc) => {
        setCurrentPdf(pdfSrc);
        setIsPreviewOpen(true);
    };

    const closePreview = () => setIsPreviewOpen(false);

    const texts = {
        en: {
            title: '2000 Years of Lies: The Falsity of the New Testament',
            description: (
                <ul className="description-list">
                    <li>Know the lies hidden beneath the "letter" of the New Testament</li>
                    <li>Find Out What the New Testament False Message of Salvation Is</li>
                    <li>Learn how they falsified the Jewish Texts used in the New Testament</li>
                    <li>Wake up and don't jeopardize your salvation</li>
                    <li>Identify what "idolatry" is in the writings of the New Testament</li>
                    <li>See how the New Testament is not a divinely inspired writing, because God never spoke anything to anyone in the New Testament.</li>
                    <li>Ask yourself why the New Testament is composed of a mixture of dualistic Gnosticism, Mystery Cult Rites of idolatrous origin, and a distorted Judaism?</li>
                </ul>
            ),
            bookTitle: '2000 Years of Lies: The Falsity of the New Testament',
            englishVersion: 'English Version',
            spanishVersion: 'Spanish Version',
            buyIunivers: 'Buy on iUniverse',
            buyOnAmazon: 'Buy on Amazon',
            preview: 'Preview',
            linkEnglishIunivers: 'https://www.iuniverse.com/en/bookstore/bookdetails/859169-2000-years-of-lies',
            linkSpanishIunivers: 'https://www.iuniverse.com/en/bookstore/bookdetails/859168-2000-anos-de-mentiras',
            linkEnglishAmazon: 'https://www.amazon.com/dp/B08R9L9G5X',  
            linkSpanishAmazon: 'https://www.amazon.com/dp/B08R9L9G5X',  
        },
        es: {
            title: '2000 Años de Mentiras: La Falsedad del Nuevo Testamento',
            description: (
                <ul className="description-list">
                    <li>Conozca las mentiras escondidas debajo de la "letra" del Nuevo Testamento</li>
                    <li>Descubra cuál es el Falso Mensaje de Salvación del Nuevo Testamento</li>
                    <li>Entérese de cómo falsificaron los Textos Judíos que utilizó el Nuevo Testamento</li>
                    <li>Despierte y no ponga en peligro su salvación</li>
                    <li>Identifique cuál es la "Idolatría" en los escritos del Nuevo Testamento</li>
                    <li>Vea cómo el Nuevo Testamento no es un escrito inspirado divinamente, porque Dios nunca habló nada a nadie en el Nuevo Testamento.</li>
                    <li>Pregúntese ¿por qué el Nuevo Testamento está compuesto de una mezcla de Gnosticismo dualista, Ritos de Cultos de Misterio de origen idolátrico y un Judaísmo distorsionado?</li>
                </ul>
            ),
            bookTitle: '2000 Años de Mentiras: La Falsedad del Nuevo Testamento',
            englishVersion: 'Versión en Inglés',
            spanishVersion: 'Versión en Español',
            buyIunivers: 'Comprar en iUniverse',
            buyOnAmazon: 'Comprar en Amazon',
            preview: 'Previsualizar',
            linkEnglishIunivers: 'https://www.iuniverse.com/en/bookstore/bookdetails/859169-2000-years-of-lies',
            linkSpanishIunivers: 'https://www.iuniverse.com/en/bookstore/bookdetails/859168-2000-anos-de-mentiras',
            linkEnglishAmazon: 'https://www.amazon.com/2000-Years-Lies-False-Testament-ebook/dp/B0D7ZKHBSW/ref=sr_1_1?dib=eyJ2IjoiMSJ9.hJzTr-OMEK3hGR6tygKn7Ciit4wOkSa0nKdCTB6u6Z0.U5kRMnOtLuYi4IAmzuAAQdKJfzgNMjc0QMtGB8t-wxg&dib_tag=se&qid=1725838913&refinements=p_27%3AJairo+Borda+Jara&s=digital-text&sr=1-1&text=Jairo+Borda+Jara',  
            linkSpanishAmazon: 'https://www.amazon.com/2000-A%C3%B1os-Mentiras-Falsedad-Testamento-ebook/dp/B0D8VL9Z2K/ref=sr_1_2?dib=eyJ2IjoiMSJ9.as9L1p-_7KLSJneq5LcxQiiit4wOkSa0nKdCTB6u6Z0.acgit197mA3gzr6E0nHwAPCm6Hvrt88HH0TKhc-P2q0&dib_tag=se&qid=1725411084&refinements=p_27%3AJairo+Borda+Jara&s=books&sr=1-2&text=Jairo+Borda+Jara',  
        }
    };

    const currentTexts = texts[language];

    return (
        <section className="buy-section" id="buy">
            <div className="promo-info">
                <div className="info-header">
                    <h2>{currentTexts.title}</h2>
                    <FaInfoCircle className="info-icon" />
                </div>
                <div className="promo-description">{currentTexts.description}</div>
            </div>

            <div className="book-options">
                <div className="book-card">
                    <img src={inglesImg} alt="Cover of the English version" className="book-image" />
                    <h3>{currentTexts.bookTitle}</h3>
                    <p className="description">{currentTexts.englishVersion}</p>
                    <p className="price">USD $20.99</p>
                    <div className="btn-group">
                        <a 
                            href={currentTexts.linkEnglishIunivers} 
                            className="btn-buy"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {currentTexts.buyIunivers}
                        </a>
                        <a 
                            href={currentTexts.linkEnglishAmazon} 
                            className="btn-buy"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {currentTexts.buyOnAmazon}
                        </a>
                        <button 
                            className="btn-preview"
                            onClick={() => openPreview(caratula_ingles)}
                        >
                            <FaFilePdf /> {currentTexts.preview}
                        </button>
                    </div>
                </div>
                <div className="book-card">
                    <img src={españolImg} alt="Cover of the Spanish version" className="book-image" />
                    <h3>{currentTexts.bookTitle}</h3>
                    <p className="description">{currentTexts.spanishVersion}</p>
                    <p className="price">USD $20.99</p>
                    <div className="btn-group">
                        <a 
                            href={currentTexts.linkSpanishIunivers} 
                            className="btn-buy"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {currentTexts.buyIunivers}
                        </a>
                        <a 
                            href={currentTexts.linkSpanishAmazon} 
                            className="btn-buy"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {currentTexts.buyOnAmazon}
                        </a>
                        <button 
                            className="btn-preview"
                            onClick={() => openPreview(caratula_español)}
                        >
                            <FaFilePdf /> {currentTexts.preview}
                        </button>
                    </div>
                </div>
            </div>
            
            <PreviewModal isOpen={isPreviewOpen} onClose={closePreview} pdfSrc={currentPdf} />
        </section>
    );
}

export default Comprar;
