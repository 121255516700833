import React from 'react';
import './Contactanos.css';
import { FaEnvelope } from 'react-icons/fa';

function Contactanos({ language }) {
    const texts = {
        en: {
            title: 'Contact the author',
            email: '2000yearsoflies@gmail.com'
        },
        es: {
            title: 'Contacta al autor',
            email: '2000yearsoflies@gmail.com'
        }
    };

    const currentTexts = texts[language] || texts.en; 

    return (
        <div className="body">
        <div className="contactanos-container">
            <div className="contactanos-info">
                <h2>{currentTexts.title}</h2>
                <div className="contactanos-details">
                    <a href={`mailto:${currentTexts.email}`} className="contact-info-item">
                        <FaEnvelope className="icon" />
                        <p>{currentTexts.email}</p>
                    </a>
                </div>
            </div>
        </div>
        </div>
    );
}

export default Contactanos;
