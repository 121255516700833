import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './Components/Assets/Navbar/Navbar';
import Principal from './Components/Assets/Cuerpo_principal/Principal';
import Comprar from './Components/Assets/Comprar_libro/Comprar';
import Biografia from './Components/Assets/Sobre_el_autor/Biografia';
import Contactanos from './Components/Assets/Contacto/Contactanos';
import Pie from './Components/Assets/Pie_pagina/Pie';
import ScrollToTop from './Components/Assets/ScrollToTop/ScrollToTop ';  // Corregido

function App() {
  const [language, setLanguage] = useState(localStorage.getItem('language') || 'es');

  useEffect(() => {
    localStorage.setItem('language', language);
  }, [language]);

  return (
    <Router>
      <div className="App">
        <Navbar setLanguage={setLanguage} language={language} />
        <ScrollToTop />
        <main className="main-content">
          <Routes>
            <Route path="/" element={<Principal language={language} />} />
            <Route path="/comprar" element={<Comprar language={language} />} />
            <Route path="/biografia" element={<Biografia language={language} />} />
            <Route path="/contactanos" element={<Contactanos language={language} />} />
          </Routes>
        </main>
        <Pie language={language} />
      </div>
    </Router>
  );
}

export default App;
