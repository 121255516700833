import React from 'react';
import './Biografia.css';
import authorPhoto from '../img/Copia de Foto3 3 (1).jpg'; 

function Biografia({ language }) {
    const bioContent = {
        es: {
            title: "Sobre el Autor",
            text: (
                <p>
                    <strong>Jairo Borda Jara </strong> nacido en Bogotá, Colombia, vivió en EE.UU. durante más de 25 años. Sirvió en la Fuerza Aérea de EE.UU. y asistió a la Universidad de Nebraska en Lincoln, donde obtuvo una Maestría en Economía. Ha sido profesor de Economía durante muchos años y actualmente vive en Bogotá.
                </p>
            )
        },
        en: {
            title: "About The Author",
            text: (
                <p>
                    <strong>Jairo Borda Jara</strong> born in Bogotá, Colombia, lived in the U.S. for over 25 years. Served in the U.S. Air Force and attended the University of Nebraska in Lincoln, where he obtained an M.A. degree in Economics. He has been an Economics Professor for many years and currently lives in Bogotá.
                </p>
            )
        }
    };

    const { title, text } = bioContent[language] || bioContent['en'];

    return (
        <div className="biografia-container">
            <div className="author-photo">
                <img src={authorPhoto} alt="Autor" />
            </div>
            <div className="author-info">
                <h1>{title}</h1>
                {text}
            </div>
        </div>
    );
}

export default Biografia;
