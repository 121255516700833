const es = {
    heroTitle: "LA FALSEDAD DEL NUEVO TESTAMENTO",
    heroDescription: "Una exploración profunda que revela cómo el Nuevo Testamento ha distorsionado los textos bíblicos judíos. Se adentra en una narrativa que desafía las enseñanzas falsas del Nuevo Testamento y expone manipulaciones ocultas y falsificaciones de los textos bíblicos judíos.",
    bookIntroTitle: "¿Por qué leer 2000 AÑOS DE MENTIRAS?",
    bookIntroText: "2000 AÑOS DE MENTIRAS no es solo un libro; es una llave maestra para desvelar las mentiras ocultas en el Nuevo Testamento. A través de una investigación meticulosa, el autor descubre cómo los textos bíblicos judíos han sido manipulados y reinterpretados para apoyar las enseñanzas falsas del Nuevo Testamento que se desvían del mensaje original de la bliblia hebrea.",
    revelationsTitle: "Revelaciones Impactantes",
    revelationsText: "El libro ofrece una visión crítica y profunda, respaldada por un análisis detallado y una comparación de los textos bíblicos judíos del Tanaj con esos mismos textos mal utilizados y manipulado en el Nuevo Testamento. El lector encontrará dos figuras diferentes de Jesús. Uno que habla como judío y otro que habla como no judío. El Nuevo Testamento está construido sobre enseñanzas de salvación falsas sin fundamento bíblico judío. Las enseñanzas están respaldadas por el gnosticismo, los cultos de la religión mistérica y un judaísmo distorsionado.",
    importanceTitle: "Importancia del Libro",
    importanceText: "Prepárese para cuestionar lo que creía saber sobre las sagradas escrituras. Este libro es esencial para cualquier persona interesada en la teología, la historia religiosa o en descubrir la verdad detrás de las enseñanzas cristianas tradicionales. Ideal para teólogos, historiadores y lectores curiosos que buscan comprender la verdadera influencia de los textos bíblicos en el cristianismo moderno."
  };
  
  export default es;
  