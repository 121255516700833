import React from 'react';
import './Modal.css';

const PreviewModal = ({ isOpen, onClose, pdfSrc }) => {
    if (!isOpen) return null;

    return (
        <div className={`modal-overlay ${isOpen ? 'open' : ''}`} onClick={onClose}>
            <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                <div className="modal-header">
                    <button className="modal-close" onClick={onClose}>✕</button>
                </div>
                <div className="modal-body">
                    <iframe
                        src={pdfSrc}
                        style={{ width: '100%', height: '100%', border: 'none' }}
                        title="PDF Preview"
                    />
                </div>
            </div>
        </div>
    );
};

export default PreviewModal;
