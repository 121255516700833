import React, { useState, useRef, useEffect } from 'react';
import './Navbar.css'; 
import { FaHome, FaBook, FaUser, FaEnvelope, FaBars, FaTimes, FaGlobe, FaChevronDown } from 'react-icons/fa'; 
import { Link } from 'react-router-dom';

function Navbar({ setLanguage, language }) {
  const [isOpen, setIsOpen] = useState(false);
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false); 
  const settingsRef = useRef(null);
  const navbarRef = useRef(null);

  const toggleNavbar = () => setIsOpen(!isOpen);
  const toggleSettings = () => setIsSettingsOpen(!isSettingsOpen);
  
  const switchLanguage = () => {
    setLanguage((prevLanguage) => (prevLanguage === 'es' ? 'en' : 'es'));
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (settingsRef.current && !settingsRef.current.contains(event.target)) {
        setIsSettingsOpen(false);
      }
      if (navbarRef.current && !navbarRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setIsMobile(true); 
      } else {
        setIsMobile(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    window.addEventListener('resize', handleResize); 

    handleResize();

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <header className="navbar" ref={navbarRef}>
      <div className="navbar-brand">
        <Link to="/" className="navbar-brand-link">
          <h1>
            {isMobile
              ? language === 'es' ? '2000 AÑOS DE MENTIRAS' : '2000 YEARS OF LIES'
              : language === 'es' ? '2000 AÑOS DE MENTIRAS - EL FALSO NUEVO TESTAMENTO' : '2000 YEARS OF LIES - THE FALSE NEW TESTAMENT'}
          </h1>
        </Link>
      </div>
      <button className="navbar-toggle" onClick={toggleNavbar}>
        {isOpen ? <FaTimes /> : <FaBars />}
      </button>
      <nav className={`navbar-links ${isOpen ? 'active' : ''}`}>
        <Link to="/"><FaHome className="icon" /> {language === 'es' ? 'Inicio' : 'Home'}</Link>
        <Link to="/biografia"><FaUser className="icon" /> {language === 'es' ? 'Acerca Del Autor' : 'About The Author'}</Link>
        <Link to="/comprar"><FaBook className="icon" /> {language === 'es' ? 'Acerca Del Libro' : 'About The Book'}</Link>
        <Link to="/contactanos"><FaEnvelope className="icon" /> {language === 'es' ? 'Contáctanos' : 'Contact Us'}</Link>
        <div className="settings-container" ref={settingsRef}>
          <button className="settings-button" onClick={toggleSettings}>
            <FaGlobe /> {language === 'es' ? 'Idioma' : 'Language'}
            <FaChevronDown className={`settings-arrow ${isSettingsOpen ? 'open' : ''}`} />
          </button>
          {isSettingsOpen && (
            <div className="settings-dropdown">
              <button className="dropdown-item" onClick={switchLanguage}>
                {language === 'es' ? 'Inglés' : 'Español'}
              </button>
            </div>
          )}
        </div>
      </nav>
    </header>
  );
}

export default Navbar;
