const en = {
    heroTitle: "THE FALSE NEW TESTAMENT",
    heroDescription: "A deep exploration that reveals how the New Testament has distorted Jewish biblical texts. It dives into a narrative that challenges false New Testament teachings and exposes hidden manipulations and falsifications of Jewish biblical texts.",
    bookIntroTitle: "Why Reading 2000 YEARS OF LIES?",
    bookIntroText: "2000 YEARS OF LIES is not just a book; it’s a master key to unlocking the hidden secrets behind the sacred scriptures. Through meticulous research, the author uncovers how jewish biblical texts have been manipulated and reinterpreted to support false New Testament teachings that deviate from the original jewish message.",
    revelationsTitle: "Amazing Revelations",
    revelationsText: "The book offers a critical and deep insight, supported by a detailed analysis and comparison of jewish biblical texts from the Tanaj with those same texts misused and manipulated in the New Testament. The reader will find two diferent Jesus figures. One that speaks like a Jew and one that speaks like a non-Jew. The New Testament is built on false salvation teachings with no Jewish Biblical foundation. The teachings are supported by Gnosticism, Mystery Religion Cults, and some distorted Judaism.",
    importanceTitle: "Importance of the Book",
    importanceText: "Prepare to question what you thought you knew about the sacred scriptures. This book is essential for anyone interested in theology, religious history, or discovering the truth behind traditional Christian teachings. Ideal for theologians, historians, and curious readers seeking to understand the true influence of biblical texts on modern Christianity."
  };
  
  export default en;
  