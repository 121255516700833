import React, { useRef, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './Principal.css';
import { FaPlay, FaPause, FaVolumeUp, FaVolumeMute, FaExpand, FaCompress, FaUnlockAlt, FaBook } from 'react-icons/fa';
import caratula_español from '../img/caratula_español.jpg';
import caratula_ingles from '../img/caratula_ingles.jpg';
import es from '../Cuerpo_principal/español';
import en from '../Cuerpo_principal/ingles';
import video from '../img/video.mp4';
import video2 from '../img/video2.mp4';

function Principal({ language }) {
  const texts = language === 'es' ? es : en;
  const portada = language === 'es' ? caratula_español : caratula_ingles;
  const navigate = useNavigate();
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(true);
  const [isMuted, setIsMuted] = useState(true);
  const [volume, setVolume] = useState(0);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [showControls, setShowControls] = useState(true);
  const [volumeSliderVisible, setVolumeSliderVisible] = useState(false);
  const [hideControlsTimeout, setHideControlsTimeout] = useState(null);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);

  useEffect(() => {
    const videoElement = videoRef.current;

    const handleMouseMove = () => {
      setShowControls(true);
      clearTimeout(hideControlsTimeout);
      setHideControlsTimeout(setTimeout(() => setShowControls(false), 3000));
    };
    
    const handleMouseLeave = () => {
      clearTimeout(hideControlsTimeout);
      setHideControlsTimeout(setTimeout(() => setShowControls(false), 3000));
    };
    
    const handleFullscreenChange = () => setIsFullscreen(document.fullscreenElement !== null);

    videoElement.addEventListener('mousemove', handleMouseMove);
    videoElement.addEventListener('mouseleave', handleMouseLeave);
    document.addEventListener('fullscreenchange', handleFullscreenChange);
    videoElement.addEventListener('timeupdate', () => {
      setCurrentTime(videoElement.currentTime);
      setDuration(videoElement.duration);
    });

    return () => {
      videoElement.removeEventListener('mousemove', handleMouseMove);
      videoElement.removeEventListener('mouseleave', handleMouseLeave);
      document.removeEventListener('fullscreenchange', handleFullscreenChange);
      videoElement.removeEventListener('timeupdate', () => {
        setCurrentTime(videoElement.currentTime);
        setDuration(videoElement.duration);
      });
      clearTimeout(hideControlsTimeout);
    };
  }, [hideControlsTimeout]);

  const handleImageClick = () => {
    navigate('/comprar');
  };

  const togglePlayPause = () => {
    const video = videoRef.current;
    if (video.paused) {
      video.play();
      setIsPlaying(true);
    } else {
      video.pause();
      setIsPlaying(false);
    }
  };

  const toggleMute = () => {
    const video = videoRef.current;
    video.muted = !video.muted;
    setIsMuted(video.muted);
    setVolume(video.muted ? 0 : video.volume);
  };

  const handleVolumeChange = (e) => {
    const video = videoRef.current;
    const newVolume = e.target.value;
    video.volume = newVolume;
    setVolume(newVolume);
    if (newVolume === 0) {
      setIsMuted(true);
    } else {
      setIsMuted(false);
    }
  };

  const toggleFullscreen = () => {
    if (document.fullscreenElement) {
      document.exitFullscreen();
      setIsFullscreen(false);
    } else {
      videoRef.current.parentNode.requestFullscreen();
      setIsFullscreen(true);
    }
  };

  const toggleVolumeSlider = () => {
    setVolumeSliderVisible(!volumeSliderVisible);
  };

  const handleVideoClick = () => {
    const video = videoRef.current;
    if (video.paused) {
      video.play();
      setIsPlaying(true);
    } else {
      video.pause();
      setIsPlaying(false);
    }
  };

  const handleProgressChange = (e) => {
    const video = videoRef.current;
    const newTime = e.target.value;
    video.currentTime = newTime;
    setCurrentTime(newTime);
  };

  return (
    <main className="main-content">
      <section className="hero">
        <video
          src={video}
          autoPlay
          muted
          loop
          width="100%"
          height="100%"
          className="hero-video"
        >
          Your browser does not support the video tag.
        </video>
        <div className="hero-content">
          <h1>{texts.heroTitle}</h1>
          <p>{texts.heroDescription}</p>
        </div>
      </section>

      <section className="book-introduction">
        <h2>{texts.bookIntroTitle}</h2>
        <p>{texts.bookIntroText}</p>
      </section>

      <section className="book-details">
        <div className="detail-item">
          <div className="detail-info">
            <h2><FaUnlockAlt className="section-icon" /> {texts.revelationsTitle}</h2>
            <p>{texts.revelationsText}</p>
          </div>
          <img
            src={portada}
            alt="Portada del libro"
            className="detail-image"
            onClick={handleImageClick}
          />
        </div>
      </section>

      <section className="book-importance">
        <h2><FaBook className="section-icon" /> {texts.importanceTitle}</h2>
      </section>

      <section className="video-section">
  <div className="video-wrapper">
    <video
      ref={videoRef}
      src={video2}
      className="video-element"
      muted={isMuted}
      autoPlay
      onClick={handleVideoClick}
    >
      Your browser does not support the video tag.
    </video>
    <div className={`video-controls ${showControls ? 'visible' : 'hidden'}`}>
      <button onClick={togglePlayPause} className={`control-button ${isPlaying ? 'playing' : 'paused'}`}>
        {isPlaying ? <FaPause /> : <FaPlay />}
      </button>
      <button onClick={toggleMute} className={`control-button ${isMuted ? 'muted' : 'unmuted'}`} onClick={toggleVolumeSlider}>
        {isMuted ? <FaVolumeMute /> : <FaVolumeUp />}
        {volumeSliderVisible && (
          <input
            type="range"
            min="0"
            max="1"
            step="0.01"
            value={volume}
            onChange={handleVolumeChange}
            className="volume-slider"
          />
        )}
      </button>
      <button onClick={toggleFullscreen} className={`control-button ${isFullscreen ? 'fullscreen' : 'windowed'}`}>
        {isFullscreen ? <FaCompress /> : <FaExpand />}
      </button>
      <div className="progress-container">
        <input
          type="range"
          min="0"
          max={duration}
          value={currentTime}
          onChange={handleProgressChange}
          className="progress-bar"
        />
      </div>
    </div>
  </div>
</section>

    </main>
  );
}

export default Principal;
