import React from 'react'; 
import { FaFacebookF, FaInstagram, FaYoutube } from 'react-icons/fa'; 
import { Link } from 'react-router-dom'; 
import './Pie.css'; 

function Pie({ language }) { 
  const textos = { 
    es: { 
      title: '2000 AÑOS DE MENTIRAS', 
      derechos: '© 2024 Todos los derechos reservados. Todos los derechos sobre el libro y contenido son propiedad del autor.', 
      links: { 
        home: 'Contenido', 
        subhome: 'Inicio', 
        buyBook: 'Acerca Del Libro', 
        aboutAuthor: 'Acerca Del Autor', 
        contactUs: 'Contacto ' 
      }, 
      contacto: 'Redes Sociales', 
      facebook: 'Facebook', 
      instagram: 'Instagram', 
      youtube: 'YouTube' 
    }, 
    en: { 
      title: '2000 YEARS OF LIES', 
      derechos: '© 2024 All rights reserved. All rights to the book and content are owned by the author.', 
      links: { 
        home: 'Content', 
        subhome: 'Home', 
        buyBook: 'About The Book', 
        aboutAuthor: 'About The Author', 
        contactUs: 'Contact Us' 
      }, 
      contacto: 'Social Networks', 
      facebook: 'Facebook', 
      instagram: 'Instagram', 
      youtube: 'YouTube' 
    } 
  }; 

  const contenido = textos[language] || textos.es;

  return ( 
    <footer className="footer"> 
      <div className="footer-content"> 
        <div className="footer-about"> 
          <h2>{contenido.title}</h2> 
          <p>{contenido.derechos}</p> 
        </div> 
        <div className="footer-links"> 
          <h3>{contenido.links.home}</h3> 
          <ul> 
            <li><Link to="/">{contenido.links.subhome}</Link></li> 
            <li><Link to="/comprar">{contenido.links.buyBook}</Link></li> 
            <li><Link to="/biografia">{contenido.links.aboutAuthor}</Link></li> 
            <li><Link to="/contactanos">{contenido.links.contactUs}</Link></li> 
          </ul> 
        </div> 
        <div className="footer-social"> 
          <h3>{contenido.contacto}</h3> 
          <div className="social-icons"> 
            <a href="https://www.facebook.com/profile.php?id=61565685898968" target="_blank" rel="noopener noreferrer" aria-label={contenido.facebook} className="social-link"> 
              <FaFacebookF /> 
            </a> 
            <a href="https://www.instagram.com/deregresoalassendasantigua1876/" target="_blank" rel="noopener noreferrer" aria-label={contenido.instagram} className="social-link"> 
              <FaInstagram /> 
            </a> 
            <a href="https://www.youtube.com/@deregresoalassendasantiguas" target="_blank" rel="noopener noreferrer" aria-label={contenido.youtube} className="social-link"> 
              <FaYoutube /> 
            </a> 
          </div> 
        </div> 
      </div> 
    </footer> 
  ); 
}

export default Pie;
